import React, { useState } from "react";
import ReservationAddFormView from "./ReservationAddFormView";

const POST_RESERVATION_PATH = "/reservation";

const DEFAULT_RESERVATION = 0;
const DEFAULT_VALUE_TYPE = "absolute";

const valueTypes = [
    { title: "Абсолютное", value: "absolute" },
    { title: "Процентное", value: "percent" },
];

const dates = [
    { title: "Текущий", value: "currentMonth" },
    { title: "Прошлый", value: "previousMonth" },
];

const ReservationAddForm = () => {
    const [reservation, setReservation] = useState(DEFAULT_RESERVATION);
    const [valueType, setValueType] = useState(DEFAULT_VALUE_TYPE);
    const [date, setDate] = useState(dates[0]?.value);
    const [submittingStatus, setSubmittingStatus] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleReservationChange = (value) => {
        setReservation(value);
    };
    const handleValueTypeChange = (value) => {
        setValueType(value);
    };
    const handleDateChange = (value) => {
        setDate(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmittingStatus("Добавляем...");
        const data = {
            reservation,
            valueType,
            date,
        };
        const url = `${process.env.REACT_APP_API_ENDPOINT}${POST_RESERVATION_PATH}`;
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
                credentials: "include",
            });
            setSubmittingStatus(
                response.ok ? "Успешно добавили" : "Ошибка при добавлении"
            );
        } catch (error) {
            console.error(error);
            setSubmittingStatus("Ошибка при добавлении");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <ReservationAddFormView
            handleReservationChange={handleReservationChange}
            handleValueTypeChange={handleValueTypeChange}
            handleDateChange={handleDateChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            submittingStatus={submittingStatus}
            valueTypes={valueTypes}
            dates={dates}
        />
    );
};

export default ReservationAddForm;
