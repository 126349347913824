import React from "react";
import { number, object, string } from "prop-types";
import Colors from "../../styles/Colors";
import Sizes from "../../styles/Sizes";
import { css, styled } from "styled-components";
import DayExpenseVisualization from "../DayExpenseVisualization";
import { formatByDigits } from "../../helpers/utils";

const widthBreakpoints = ["640px", "560px", Sizes.widthMobileMax];

const Cell = styled.div`
    &:nth-child(1) {
        min-width: 128px;

        @container (width <= ${widthBreakpoints[0]}) {
            min-width: 88px;
        }
    }
    &:nth-child(2) {
        min-width: 96px;
    }
    &:nth-child(3) {
        padding-left: 24px;
        flex-grow: 1;
    }
    &:nth-child(4) {
        min-width: 96px;
    }
    &:nth-child(5) {
        min-width: 96px;
    }

    ${(props) =>
        props.onClick &&
        css`
            &:hover {
                cursor: pointer;
            }
        `}
`;

const Long = styled.span``;

const Short = styled.span`
    display: none;
`;

const TodayIndicator = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: ${Colors.red0};
`;

const MonoNumbers = styled.span`
    font-variant-numeric: lining-nums tabular-nums;
`;

const Date = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    color: ${Colors.greySecondaryText};

    @container (width <= ${widthBreakpoints[0]}) {
        ${Long} {
            display: none;
        }

        ${Short} {
            display: inline;
        }
    }
`;

const Budget = styled.div`
    display: flex;

    align-items: flex-start;
    justify-content: flex-end;
`;

const Total = styled.div`
    display: flex;
    min-width: 72px;

    align-items: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    justify-content: flex-end;
`;

const Balance = styled.div`
    display: flex;

    align-items: flex-start;
    justify-content: flex-end;

    color: ${Colors.green80};
    ${({ $negative }) => $negative && `color: ${Colors.red80};`}
`;

const FlexWrapper = styled.div`
    position: relative;
    overflow: visible;
    line-height: 20px;
    padding: 4px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: -6px;
        right: -6px;
        border-radius: 4px;
        background-color: ${Colors.grey3PercentBackground};
        z-index: -1;
        opacity: 0;
        transition: opacity 0.1s ease;
    }

    &:hover::after {
        opacity: 1;
    }

    @container (width <= ${widthBreakpoints[2]}) {
        padding: 8px 0 16px 0;

        ${Cell} {
            display: flex;
            justify-content: space-between;
            width: 100%;

            &:nth-child(2) {
                width: calc(33% - 8px);
            }
            &:nth-child(3) {
                order: 1;
                padding: 0;
            }
            &:nth-child(4) {
                width: calc(33% - 8px);
                justify-content: center;
            }
            &:nth-child(5) {
                width: calc(33% - 8px);
                justify-content: flex-end;
            }
        }
        ${Total} {
            min-width: initial;
        }
    }
`;

const StyledDayExpenseListItem = styled.div`
    align-self: stretch;
    cursor: default;
    container-type: inline-size;

    ${({ $byTime }) => {
        switch ($byTime) {
            case "future":
                return css`
                    & ${Budget} {
                        color: ${Colors.greySecondaryText};
                    }
                `;
            case "today":
                return css`
                    & ${Date} {
                        color: ${Colors.black};
                    }
                `;
        }
    }}

    ${({ $order }) => $order && `order: ${$order};`}
`;

const DayExpenseListItem = ({
    dateString,
    dateStringShort,
    byTime,
    budget,
    expenses,
    balance,
    order,
}) => {
    return (
        <StyledDayExpenseListItem $byTime={byTime} $order={order}>
            <FlexWrapper>
                <Cell>
                    <Date>
                        <Long>{dateString}</Long>
                        <Short>{dateStringShort}</Short>
                        {byTime === "today" && <TodayIndicator />}
                    </Date>
                </Cell>
                <Cell>
                    <Budget>
                        <MonoNumbers>{formatByDigits(budget)}</MonoNumbers>
                    </Budget>
                </Cell>
                {byTime === "future" ? (
                    <>
                        <Cell></Cell>
                        <Cell></Cell>
                    </>
                ) : (
                    <>
                        <Cell>
                            <DayExpenseVisualization
                                list={expenses.list}
                                total={expenses.total}
                                normalizedByMaxTotal={
                                    expenses.normalizedByMaxTotal
                                }
                            />
                        </Cell>
                        <Cell>
                            <Total>
                                <MonoNumbers>
                                    {formatByDigits(expenses.total)}
                                </MonoNumbers>
                            </Total>
                        </Cell>
                    </>
                )}
                <Cell>
                    <Balance $negative={balance < 0}>
                        <MonoNumbers>
                            {byTime !== "future" && formatByDigits(balance)}
                        </MonoNumbers>
                    </Balance>
                </Cell>
            </FlexWrapper>
        </StyledDayExpenseListItem>
    );
};

DayExpenseListItem.propTypes = {
    dateString: string,
    dateStringShort: string,
    byTime: string,
    budget: number,
    expenses: object,
    balance: number,
    order: number,
};

export default DayExpenseListItem;
